/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui'
import React, { useState } from 'react'

import { Nav } from '@giraldomac/gatsby-theme-mindmerge/src/components/layout'

const Toggle = ({ color = 'black' }) => {
  const [isToggledOn, setToggle] = useState(false)
  const toggle = () => setToggle(!isToggledOn)

  return (
    <Box
      className="mobile-nav"
      sx={{
        display: ['block', 'block', 'none'],
        cursor: 'pointer',
      }}
    >
      <button
        onClick={toggle}
        aria-label={`${isToggledOn ? 'close menu' : 'open menu'}`}
        sx={{
          zIndex: 30,
          top: '-10px',
          position: 'relative',
          background: 'transparent',
          border: 'none',
          cursor: 'pointer',
          padding: '8px 15px',
          ':hover:not(.touch)': {
            background: 'transparent',
            border: 'none',
            outline: 'none',
          },
          ':focus': {
            background: 'transparent',
            border: 'none',
            outline: 'none',
          },
        }}
      >
        <Box
          sx={{
            width: '24px',
            height: '2px',
            background: `${color}`,
            position: 'absolute',
            left: 0,
            background: isToggledOn ? 'transparent' : color,
            transition: 'all 250ms cubic-bezier(0.86, 0, 0.07, 1)',
            '::before': {
              content: '" "',
              top: '-8px',
              width: '24px',
              height: '2px',
              background: isToggledOn ? 'white' : color,
              position: 'absolute',
              left: 0,
              transform: isToggledOn ? 'rotate(45deg); top:0' : 'rotate(0)',
              transition: 'all 250ms cubic-bezier(0.86, 0, 0.07, 1)',
            },
            '::after': {
              top: '8px',
              content: '" "',
              width: '24px',
              height: '2px',
              background: isToggledOn ? 'white' : color,
              position: 'absolute',
              left: 0,
              transform: isToggledOn ? 'rotate(-45deg); top:0' : 'rotate(0)',
              transition: 'all 250ms cubic-bezier(0.86, 0, 0.07, 1)',
            },
          }}
        />
      </button>
      {isToggledOn ? (
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: `calc(100% + 100vh)`,
            width: 'full',
            display: 'flex',
            backgroundColor: 'primary',
            opacity: 1,
            transition: `opacity 0.30s, visibility 0.30s`,
            zIndex: 20,
          }}
        >
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'nowrap',
              padding: '8em 1em 0 1em',
              ul: {
                flexDirection: 'column',
                margin: 0,
                padding: 0,
              },
              'li a': {
                color: 'white',
                display: 'block',
                width: '100%',
                fontSize: 3,
                padding: '0.5rem 0',
                textAlign: 'center',
                textDecoration: 'none',
              },
              'li a.active': {
                color: 'secondary',
              },
              'li a:hover': {
                color: 'secondary',
              },
              'li a svg': {
                display: 'none',
              },
              'svg *': {
                fill: 'black',
              },
            }}
          >
            <Nav />
          </Container>
        </Box>
      ) : (
          <Box
            sx={{
              opacity: 0,
              visibility: 'hidden',
            }}
          />
        )}
    </Box>
  )
}

export default Toggle
