/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import React, { useEffect, useRef, useState } from 'react'

import Sticky from './Sticky'

const Header = (props) => {
  const [isSticky, setSticky] = useState(false)
  const ref = useRef(null)
  const handleScroll = () => {
    if (ref && ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  return (
    <Flex
      as="header"
      id="home"
      className={`sticky-wrapper${isSticky ? ' sticky' : ''}`}
      sx={{
        flexWrap: 'wrap',
        my: isSticky ? '1px' : '1px',
        width: 'full',
      }}
      ref={ref}
    >
      <Sticky />
    </Flex>
  )
}

export default Header
