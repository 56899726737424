/** @jsx jsx */
import { jsx, Box, Flex, Container, Divider } from 'theme-ui'
import { Link } from 'gatsby'

const Footer = ({ siteName }) => {
  const d = new Date()
  const n = d.getFullYear()

  return (
    <Box as="footer" sx={{ bg: 'blue.6', fontSize: ['0'] }}>
      <Container sx={{ px: '1em' }}>
        <Flex
          mx={[0, 0, 0, -4]}
          py={5}
          sx={{
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Divider sx={{ bg: 'blue.7', mx: 0, width: 'full' }} />
          <Box
            px={[0, 0, 0, 4]}
            sx={{ color: 'white', width: ['full', '1/2'] }}
          >
            &copy; {n} Copyright - {siteName}
          </Box>
          <Box
            px={[0, 0, 0, 4]}
            sx={{
              color: 'white',
              textAlign: ['left', 'right'],
              width: ['full', '1/2'],
            }}
          >
            <Link
              to="/fcoi-policy"
              sx={{ color: 'white', '&:visited': { color: 'white' } }}
            >
              FCOI Policy
            </Link>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default Footer
