/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'emotion-theming'
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav'
import { Helmet } from 'react-helmet'

import theme from '@giraldomac/gatsby-theme-mindmerge/src/gatsby-plugin-theme-ui'
import Global from '@giraldomac/gatsby-theme-mindmerge/src/utils/global'
import {
  Footer,
  Header,
} from '@giraldomac/gatsby-theme-mindmerge/src/components/layout'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={(data) => (
      <ThemeProvider theme={theme}>
        <Helmet>
          <html lang="en" />
        </Helmet>
        <Global />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: `100vh`,
          }}
        >
          <SkipNavLink />

          <Header />
          <SkipNavContent />
          {children}
          <Footer siteName={data.site.siteMetadata.siteTitle}  />
        </Box>
      </ThemeProvider>
    )}
  />
)

export default Layout
