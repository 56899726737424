import theme from '@giraldomac/gatsby-theme-mindmerge/src/gatsby-plugin-theme-ui/index';

export default {
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#27aae1',
    primaryHover: '#005EB8',
    secondary: '#EAAA00',
    secondaryHover: '#ED8B00',
    'blue': [
      null,
      '#F3F4F6',
      '#E6E9EE',
      '#C2C7D4',
      '#9DA5BA',
      '#536287',
      '#091E53',
      '#081B4B',
      '#051232',
      '#040E25',
      '#030919'
    ],
  },
  sizes: {
    ...theme.sizes,
    container: '1024px',
  },
  buttons: {
    ...theme.buttons,
    primary: {
      ...theme.buttons.simple,
      bg: 'primary',
      textTransform: 'uppercase',
      fontSize: 0,
      letterSpacing: '1px',
      transition: 'all .15s ease',
      '&:hover': {
        bg: 'primaryHover',
      },
    },
    secondary: {
      ...theme.buttons.simple,
      bg: 'secondary',
      textTransform: 'uppercase',
      fontSize: 0,
      letterSpacing: '1px',
      transition: 'all .15s ease',
      '&:hover': {
        bg: 'secondaryHover',
      },
    },
    outline: {
      ...theme.buttons.outline,
      textTransform: 'uppercase',
      fontSize: 0,
      letterSpacing: '1px',
      transition: 'all .15s ease',
    },
    inverted: {
      ...theme.buttons.outline,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'white',
      color: 'white',
      bg: 'transparent',
      textTransform: 'uppercase',
      fontSize: 0,
      letterSpacing: '1px',
      transition: 'all .15s ease',
      '&:hover': {
        bg: 'primaryHover',
        color: 'white',
      },
    },
  },
  cards: {
    ...theme.cards,
    stacked: {
      boxShadow: 'lg',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      width: ['full', 'full', '1/3'],
      bg: 'white',
      my: 3,
    },
    full: {
      display: 'flex',
      flexWrap: 'wrap',
      width: ['full'],
      bg: 'gray.5',
      color: 'white',
    },
  },
  styles: {
    ...theme.styles,
    h1: {
      ...theme.styles.h1,
      color: `heading`,
      fontSize: [6, 6, 7],
      fontWeight: 200,
      lineHeight: 'tight',
    },
    h2: {
      ...theme.styles.h2,
      color: `heading`,
      fontSize: [3, 4, 5],
      fontWeight: 200,
    },
    h3: {
      ...theme.styles.h3,
      color: `heading`,
      fontSize: [2, 3, 4],
      fontWeight: 200,
    },
    h4: {
      ...theme.styles.h4,
      color: `heading`,
      fontSize: [1, 2, 3],
      fontWeight: 200,
    },
    h5: {
      ...theme.styles.h5,
      color: `heading`,
      fontSize: [1, 1, 2],
      fontWeight: 200,
    },
  },
};
