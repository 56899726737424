/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from 'gatsby'

import { NavLink } from '@giraldomac/gatsby-theme-mindmerge/src/components/elements'

const Nav = (props) => (
  <Box as="ul" {...props}>
    <NavLink>
      <AnchorLink href="#company" activeClassName="active">
        <Link to="/">Company</Link>
      </AnchorLink>
    </NavLink>
    <NavLink>
      <AnchorLink href="#product" activeClassName="active">
        <Link to="/">Product & Technology</Link>
      </AnchorLink>
    </NavLink>
    <NavLink>
      <AnchorLink href="#collaborations" activeClassName="active">
        <Link to="/">Collaborations</Link>
      </AnchorLink>
    </NavLink>
    <NavLink>
      <AnchorLink href="#news" activeClassName="active">
        <Link to="/">News</Link>
      </AnchorLink>
    </NavLink>
    <NavLink>
      <AnchorLink href="#contact" activeClassName="active">
        <Link to="/">Contact</Link>
      </AnchorLink>
    </NavLink>
  </Box>
)

export default Nav
