/** @jsx jsx */
import { jsx, Box, Flex, Container } from 'theme-ui'
import { Link } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import {
  Nav,
  MobileNav,
} from '@giraldomac/gatsby-theme-mindmerge/src/components/layout'
import { Logo } from '@giraldomac/gatsby-theme-mindmerge/src/components/elements'

function Sticky(props) {
  return (
    <Box
      className="sticky-inner"
      sx={{
        backgroundColor: 'white',
        borderBottomStyle: 'solid',
        borderBottomColor: 'gray.1',
        py: [2, 3],
        minHeight: ['50px'],
        width: ['full'],
      }}
    >
      <Container sx={{ px: [3] }}>
        <Flex
          sx={{
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: ['4/5', '4/5', '1/4'],
              a: {
                color: 'primary',
                display: 'block',
                position: 'relative',
                padding: 0,
                margin: 0,
                width: 'full',
                zIndex: 40,
              },
              '.logo': {
                display: 'block',
                position: 'relative',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                py: '0',
              },
            }}
          >
            <AnchorLink href="#home" aria-label="ExesaLibero Pharma">
              <h1 sx={{ m: 0 }}>
                <Link to="/">
                  <Logo />
                </Link>
              </h1>
            </AnchorLink>
          </Box>

          <Nav
            sx={{
              display: ['none', 'none', 'block'],
              textAlign: 'right',
              a: {
                color: 'gray.7',
                textDecoration: 'none',
              },
              'a:hover, a.active': {
                color: 'black',
              },
            }}
          />
          <MobileNav />
        </Flex>
      </Container>
    </Box>
  )
}

export default Sticky
