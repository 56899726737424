import React from 'react'
import { Global as GlobalCss, css } from '@emotion/core'
import theme from '../gatsby-plugin-theme-ui'

const Global = () => {
  const breakpoints = [640, 768, 1024, 1280]

  const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`)

  return (
    <GlobalCss
      styles={css`
        html {
          box-sizing: border-box;
          margin: 0;
          border: 0;
        }
        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }
        a,
        a:link {
          color: ${theme.colors.gray[8]};
        }
        h1,
        h3,
        h4,
        h5,
        h6 {
          font-family: ${theme.fonts.heading};
          line-height: 1.25;
        }
        h1 {
          font-size: ${theme.fontSizes[5]};
          ${mq[1]} {
            font-size: ${theme.fontSizes[6]};
          }
        }
        h2 {
          font-family: ${theme.fonts.body};
          font-size: 0.9em;
          letter-spacing: 0.05em;
          text-transform: uppercase;
        }
        h2:after {
          display: block;
          background-color: ${theme.colors.primary};
          width: 3rem;
          content: ' ';
          height: 3px;
          margin: 2% 0;
        }
        h3 {
          font-family: ${theme.fonts.serif};
          font-size: ${theme.fontSizes[3]};
          font-weight: 500;
          ${mq[1]} {
            font-size: ${theme.fontSizes[4]};
          }
        }
        h4 {
          font-size: ${theme.fontSizes[1]};
          ${mq[1]} {
            font-size: ${theme.fontSizes[2]};
          }
        }
        h5 {
          font-size: ${theme.fontSizes[1]};
          ${mq[1]} {
            font-size: ${theme.fontSizes[2]};
          }
        }
        footer {
          a,
          a:link,
          a:visited {
            color: white;
          }
        }
        #fcoi {
          h2:after {
            display: block;
            background-color: ${theme.colors.primary};
            width: 3rem;
            content: ' ';
            height: 0;
            margin: 0;
          }
          ul {
            list-style: none;
          }
          li {
            margin: 1em 0;
          }
        }
        .logo {
          width: 200px;
          transition: width 0.15s ease-out;
          .symbol {
            fill: ${theme.colors.primary};
          }
          .type {
            fill: #3e3e49;
          }
        }
        .sticky {
          .logo {
            transition: width 0.15s ease-out;
            width: 140px;
          }
        }
        .sticky-wrapper {
          position: relative;
          height: 6.35rem; /* We need to change this value */
        }
        .sticky .sticky-inner {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: 1;
        }
        .text-lg {
          font-size: ${theme.fontSizes[2]};
        }
        .text-sm {
          font-size: ${theme.fontSizes[0]};
        }
        [data-reach-skip-link] {
          background: white;
          left: -99999em;
          padding: 0.5em;
          position: absolute;
          z-index: 10;
        }
        [data-reach-skip-link]:focus {
          left: 0.5em;
          top: 0.5em;
        }
      `}
    />
  )
}

export default Global
